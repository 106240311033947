import React, { useEffect } from 'react'
import Nav from '../components/base/nav/Nav'
import Footer from '../components/base/footer/Footer'
import Body from '../components/base/body/Body'
import { useLocation } from 'react-router-dom'

const Default = (props) => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])

    return (
        <>
            <Nav />
            <Body>
                {props.children}
            </Body>
            <Footer />
        </>
    )
}

export default Default