import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getBlogs } from "../store/firebase/firebase";
import { Slice } from "../store/_redux/base/slice";

export const useBlogs = () => {
    const blogs = useSelector((state) => state.base.blogs);
    const dispacth = useDispatch();
    useEffect(() => {
        getBlogs()
            .then((res) => {
                if (res) {
                    dispacth(Slice.actions.setBlogs(res))
                }
            }).catch((err) => {

            })
    }, [dispacth])

    return blogs;
}