import './App.css';
import { Route, Routes } from 'react-router-dom';
import store, { persistor } from "./store/Store";
import * as _redux from "./store/index";
import axios from "axios";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { routes } from './routes/routes';
import DefaultGuard from './guards/DefaultGuard';
import Default from './layouts/Default';
import Home from './pages/Home';
import DefaultErrorPage from './errors/DefaultErrorPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

_redux.setupAxios(axios, store);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={"Loading"} persistor={persistor}>
        <Routes>
          {
            routes.map((item, i) => {
              return (
                <Route key={i} path={item.path} element={
                  <DefaultGuard errorPage={item.errorPage} permit={item.permit}>
                    <item.layout>
                      <Helmet title={item.title} />
                      <item.component />
                    </item.layout>
                  </DefaultGuard>
                } />
              )
            })
          }
          <Route key={100} path="/:path" element={
            <DefaultGuard errorPage={DefaultErrorPage} permit={["anyAllow"]}>
              <Default>
                <Home />
              </Default>
            </DefaultGuard>
          } />
        </Routes>
        <ToastContainer />
      </PersistGate>
    </Provider>
  );
}

export default App;
