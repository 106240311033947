import React, { memo } from 'react'
import greyArrow from '../../assets/images/greyArrowRight.svg';
import { useTranslation } from 'react-i18next';
const BlogCard = (props) => {
    const { item } = props;
    const { i18n } = useTranslation();
    const getCategory = (item) => {
        const category = props.categories?.filter((obj) => obj.id === item.categoryId)[0];
        return category ? category["name-" + i18n.language] : item.categoryId;
    }
    return (
        <div
            style={{
                minWidth: props.maxSize ? 427 : 282
            }}
            className={'blogCard ' + props.className}
            onClick={() => props.click()}>
            <img src={props.item.imageUrl} className='blogImg' alt='' />
            <label className='blogCategory'>
                {getCategory(item)}
            </label>
            <span className='blogTitle'>
                {props.item['title-' + i18n.language]}
            </span>
            <div className='blogBottomFrame'>
                <img src={greyArrow} alt='' />
            </div>
        </div>
    )
}

export default memo(BlogCard)