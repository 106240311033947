import React, { memo, useEffect, useState } from 'react'
import Row from '../row/Row'
import './hero.css';
import { useTranslation } from 'react-i18next';
import BaseButton from '../buttons/BaseButton';
import Squares from './Squares';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Hero = () => {
    const { t } = useTranslation();
    const categories = useSelector((state) => state.base.categories);
    const navigate = useNavigate();
    const [selected, setSelected] = useState(0);
    useEffect(() => {
        const timer = setInterval(() => {
            setSelected((current) => {
                return current !== 8 ? current + 1 : 0
            })
        }, 1000);

        return () => clearInterval(timer);
    }, [])

    return (
        <Row>
            <section className='hero'>
                <div className='heroLeft'>
                    <h2 className='heroTitle'>
                        {t('heroTitle')}
                    </h2>
                    <label className='heroText'>
                        {t('heroText')}
                    </label>
                    <BaseButton
                        onClick={() => navigate('/blog')}
                        mt={24}
                        withArrow
                        text={t('readyToStart')} />
                </div>
                <div className='heroRight'>
                    {
                        categories.map((item, i) => {
                            return (
                                <Squares
                                    item={item}
                                    isSelected={i === selected}
                                    index={i}
                                    key={item.id}
                                />
                            )
                        })
                    }
                </div>
            </section>
        </Row>
    )
}

export default memo(Hero);