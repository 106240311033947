import React from 'react'
import './Row.css';
const Row = (props) => {
    return (
        <div
            className={`row ${props.className}`}
            style={{
                ...props.style,
                justifyContent: props.center ? 'center' : props.split ? 'space-between' : 'flex-start',
                marginTop: props.mt,
                marginBottom: props.mb,
                flexDirection: props.vertical ? 'column' : 'row',
            }}>
            {props.children}
        </div>
    )
}

export default Row