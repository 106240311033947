import React, { memo } from 'react'
import arrow from '../../assets/images/greenArrow.svg';
const BlogButton = (props) => {
    return (
        <div className='blogButtonFrame' style={props.style}>
            <section className='blogButtonLine' />
            {
                props.secondaryText ?
                    <button className='blogButton' onClick={() => props.secondaryClick()}>
                        <img className='arrowReverse' src={arrow} alt='' />
                        {props.secondaryText}
                    </button> : null
            }
            {
                !props.ignoreDefaultClick ?
                    <button className='blogButton' onClick={() => props.click()}>
                        {props.text}
                        <img src={arrow} alt='' />
                    </button> : null
            }
            <section className='blogButtonLine' />
        </div>
    )
}

export default memo(BlogButton)