import React from 'react'
import Hero from '../components/base/hero/Hero'
import ContentRow from '../components/content/ContentRow'
import BlogCardsRow from '../components/blog/BlogCardsRow'
import { useNavigate } from 'react-router-dom'
import { useCategories } from '../hooks/useCategories'
import { useBio } from '../hooks/useBio'
const Home = () => {
    const navigate = useNavigate();
    const categories = useCategories();
    const bio = useBio();
    const contentClick = () => {
        navigate('/about', { state: { bio: bio } });
    }
    return (
        <>
            <Hero />
            {
                bio ?
                    <ContentRow
                        imageFirst={false}
                        click={() => contentClick(0)}
                        item={bio} /> :
                    null
            }
            <BlogCardsRow categories={categories} />
        </>
    )
}
export default Home