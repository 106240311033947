import React from 'react'

const DefaultGuard = (props) => {
    const checkPermit = () => {
        if(props.permit.includes('anyAllow')){
            return props.children
        }
        return <props.errorPage/>
    }
    return (
        <>
            {checkPermit()}
        </>
    )
}

export default DefaultGuard