// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, doc, getDoc, where, query, addDoc } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export const getBlogs = async () => {
    try {
        const querySnapshot = await getDocs(collection(db, "blog"));
        let data = [];
        querySnapshot.forEach((doc) => {
            data.push({
                ...doc.data(),
                id: doc.id,
            })
        });
        return data;
    } catch (error) {
        return [];
    }

}

export const getCategories = async () => {
    try {
        const querySnapshot = await getDocs(collection(db, "category"));
        let data = [];
        querySnapshot.forEach((doc) => {
            data.push({
                ...doc.data(),
                id: doc.id,
            })
        });
        return data;
    } catch (error) {
        return [];
    }
}
export const getBio = async () => {
    try {
        const querySnapshot = await getDocs(collection(db, "biography"));
        let data = [];
        querySnapshot.forEach((doc) => {
            data.push({
                ...doc.data(),
                id: doc.id,
            })
        });
        return data;
    } catch (error) {
        return [];
    }
}
export const getBlogsByCategory = async (id) => {
    try {
        const postCollection = collection(db, 'blog');
        const q = query(postCollection, where('categoryId', '==', id));
        const querySnapshot = await getDocs(q);
        let data = [];
        querySnapshot.forEach((doc) => {
            data.push({
                ...doc.data(),
                uniqid: doc.id,
            })
        });
        return data;
    } catch (error) {
        return null
    }
}
export const getBlogWithId = async (id) => {
    try {
        const docRef = doc(db, "blog", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            return null
        }
    } catch (error) {
        return null
    }
}
export const AddMail = async (data) => {
    try {
        const postCollection = collection(db, 'mail');
        const q = query(postCollection, where('eMail', '==', data.eMail));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
            await addDoc(collection(db, "mail"), data);
            return 1;
        }
        else {
            return 0
        }
    } catch (e) {
        return -1;
    }
}
export default app;