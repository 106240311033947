import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Belt from '../components/belt/Belt';
import Row from '../components/base/row/Row';
import BlogCard from '../components/blog/BlogCard';
import '../components/base/footer/Footer.css';
import BlogButton from '../components/blog/BlogButton';
import { checkIncludes } from '../helper/checkInclude';
import { useLocation, useNavigate } from 'react-router-dom';
import { isEmpty } from '../helper/isEmpty';
import { useBlogs } from '../hooks/useBlogs';
import { useCategories } from '../hooks/useCategories';
const Blog = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const category = location?.state?.item;
    const { t, i18n } = useTranslation();
    const allBlogs = useBlogs();
    const allCategories = useCategories();
    const [blogs, setBlogs] = useState(null);
    const [data, setData] = useState(null);
    const [categories, setCategories] = useState(null);
    const [filter, setFilter] = useState("");
    const [selected, setSelected] = useState(null);
    const [page, setPage] = useState(1);
    const length = blogs?.length;
    const pageCount = blogs % 10 === 0 ? length / 10 : Number(((length / 10) - 1).toFixed(0));
    function scrollToTop() {
        window.scrollTo({
            top: 250,
            behavior: 'smooth' // Bu, animasyonlu bir kaydırma sağlar
        });
    }
    const newer = () => {

        if (page > 1) {
            setPage((current) => {
                return current - 1
            })
            scrollToTop();
        }
    }
    const older = () => {
        if (page <= pageCount) {
            setPage((current) => {
                return current + 1
            })
            scrollToTop();
        }
    }
    const getCategory = (item) => {
        const category = categories?.filter((obj) => obj.id === item.categoryId)[0];
        return category["name-" + i18n.language];
    }
    const handleFilter = (text) => {
        const filterText = text.toLowerCase();
        setFilter(() => { return text });
        setBlogs(() => {
            return data?.filter((obj) => checkIncludes(obj, filterText) || getCategory(obj).toLowerCase().includes(filterText))
        })
    }
    const handleCategory = (category) => {
        setSelected(() => { return category })
        setBlogs(() => {
            return data?.filter((obj) => getCategory(obj).toLowerCase().includes(category.toLowerCase()))
        })
    }
    const getSearchBox = (flag) => {
        return (
            <div
                style={{
                    borderStyle: 'solid',
                    borderColor: '#E4E5E2',
                }}
                className={flag ? 'footerInputFrame blogInput mobileSearch' : 'footerInputFrame blogInput'}>
                <input
                    value={filter}
                    onChange={(e) => handleFilter(e.target.value)}
                    placeholder={t('Konu, haber, blog içeriği')}
                    className='footerInput' />
                <button className='footerButton'>
                    {t('Ara')}
                    <img alt='' />
                </button>
            </div>
        )
    }
    const goCard = (item) => {
        navigate(`/blogDetail/${item.id}`, { state: { blog: item } })
    }
    useEffect(() => {
        if (allBlogs && !blogs) {
            setBlogs(allBlogs)
        }
    }, [allBlogs])
    useEffect(() => {
        setCategories(allCategories)
    }, [allCategories])

    useEffect(() => {
        if (!isEmpty(category)) {
            setSelected(() => { return category["name-" + i18n.language] })
            setBlogs(() => {
                return allBlogs?.filter((obj) => obj.categoryId === category.id)
            })
        }
        else {
            setBlogs(() => { return allBlogs })
        }
        setData(() => { return allBlogs })
    }, [allBlogs])

    return (
        <>
            <Belt />
            <Row className="blogRow" split style={{ alignItems: 'flex-start' }}>
                <div className='blogLeft'>
                    {getSearchBox(true)}
                    <span className='lastWritten' style={{ marginTop: 32 }}>
                        {t('Son yazılar')}
                    </span>
                    <article className='blogsPlace'>
                        {
                            blogs?.slice((page - 1) * 10, page * 10)?.map((item, i) => {
                                return (
                                    <BlogCard
                                        click={() => goCard(item)}
                                        categories={categories}
                                        maxSize
                                        key={item.id}
                                        item={item} />
                                )
                            })
                        }

                    </article>
                    <BlogButton
                        secondaryClick={() => newer()}
                        secondaryText={page != 1 ? t("Daha yeni") : null}
                        ignoreDefaultClick={page > pageCount}
                        click={() => older()}
                        text={t("Daha eski")}
                        style={{ maxWidth: 894, marginLeft: -16 }} />
                </div>
                <div className='blogRight' >
                    {getSearchBox(false)}
                    <div className='catFrame'>
                        <section className='catSection'>
                            {t('Kategoriler')}{selected ? ` (${selected})` : ''}
                        </section>
                        {
                            categories?.map((item, i) => {
                                return (
                                    <a key={i} className='categories' onClick={() => handleCategory(item["name-" + i18n.language])}>
                                        {item["name-" + i18n.language]}
                                    </a>
                                )
                            })
                        }
                    </div>
                </div>
            </Row >

        </>
    )
}

export default Blog