import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Row from '../components/base/row/Row'
import Belt from '../components/belt/Belt';
import { getBlogWithId, getBlogsByCategory } from '../store/firebase/firebase';
import { useTranslation } from 'react-i18next';
import BlogCard from '../components/blog/BlogCard';
import { useSelector } from 'react-redux';
import BlogCardsRow from '../components/blog/BlogCardsRow';
function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}
const BlogDetail = () => {
    const location = useLocation();
    const selected = location?.state?.blog;
    const navigate = useNavigate();
    const id = window.location.href.split('/').pop();
    const { i18n, t } = useTranslation();
    const lang = i18n.language;
    const [blog, setBlog] = useState(selected);
    const [similarBlogs, setSimilarBlogs] = useState(null);
    const categories = useSelector((state) => state.base.squares);
    const getBlogs = (id) => {
        getBlogsByCategory(id)
            .then((res) => {
                if (res) {
                    setSimilarBlogs(() => {
                        return shuffleArray(res)?.slice(0, 4);
                    })
                }
            })
    }
    const changeBlog = (item) => {
        setBlog(() => { return item })
        navigate(`/blogDetail/${item.uniqid}`, { state: { blog: item } })
    }
    useEffect(() => {
        if (selected == null || typeof selected === 'undefined') {
            getBlogWithId(id)
                .then((res) => {
                    if (res) {
                        getBlogs(res.categoryId);
                        setBlog(() => { return res });
                    }
                    else {
                        navigate('/');
                    }
                })
        }
        else {
            getBlogs(blog.categoryId);
        }
    }, [])

    return (
        <>
            <Belt />
            <Row className="blogDetail" split>
                <div className='blogDetailLeft'>
                    <img className='blogDetailImg' src={blog ? blog.imageUrl : null} />
                    <div>
                        <h1>
                            {blog ? blog["title-" + lang] : null}
                        </h1>
                        <article className='blogDesc' dangerouslySetInnerHTML={{ __html: blog ? blog["description-" + lang] : null }} />
                    </div>
                </div>
                <div className='blogDetailRight'>
                    <label className='blogDetailTitle'>
                        {t('similarContents')}
                    </label>
                    {
                        similarBlogs?.filter((obj) => obj["title-" + lang] !== blog["title-" + lang])?.map((item, i) => {
                            return (
                                <BlogCard
                                    click={() => changeBlog(item)}
                                    className={"minwblogCard"}
                                    categories={categories}
                                    key={item.id}
                                    item={item} />
                            )
                        })
                    }

                </div>
            </Row>
            <BlogCardsRow
                customClick={(item) => changeBlog(item)}
                customTitle={blog ? t('similarContents') : null}
                className="blogDetailBottom"
                categories={categories} />
        </>

    )
}

export default BlogDetail