import { persistReducer, persistStore } from "redux-persist";
import { createStore } from "redux";
import storage from "redux-persist/lib/storage";
import * as base from '../_redux/base/slice';
import { encryptTransform } from "redux-persist-transform-encrypt";
import { reduxKey } from "../../data/staticDatas";


export const baseReducer = persistReducer(
  {
    transforms: [
      encryptTransform({
        secretKey: reduxKey,
        onError: function (error) {
          // Handle the error.
        },
      }),
    ],
    storage,
    key: "base",
    blacklist: [],
  },
  base.Slice.reducer
);

const baseStore = createStore(baseReducer);
persistStore(baseStore);
