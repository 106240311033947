import img1 from '../assets/images/photo1.jpg';
import img2 from '../assets/images/photo2.jpg';
import img3 from '../assets/images/photo3.jpg';

export const reduxKey = "1NGkvAuMw0S3jqtZaVYQlvvbJhAl2M"
export const apiUrl = "";

export const navItems = [
    {
        id: 0,
        text: "Home",
        path: "/",
    },
    {
        id: 1,
        text: "About",
        path: "/about",
    },
    {
        id: 2,
        text: "Blog",
        path: "/blog",
    },
    // {
    //     id: 3,
    //     text: "Contact",
    //     path: "/contact"
    // }
]



export const squareData = [
    {
        id: 0,
        text: "Zayıflama",
        isSelected: true,
        color: "#FF5D47E5",
        img: img1,
        src:"https://images.unsplash.com/photo-1512621776951-a57141f2eefd?auto=format&fit=crop&q=60&w=800&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Zm9vZHxlbnwwfDB8MHx8fDA%3D",
    },
    {
        id: 1,
        text: "Kilo Alma",
        isSelected: false,
        color: "#476FFFE5",
        img: img2,
        src:"https://images.unsplash.com/photo-1493770348161-369560ae357d?auto=format&fit=crop&q=60&w=800&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGZvb2R8ZW58MHwwfDB8fHww"
    },
    {
        id: 2,
        text: "Sağlıklı Beslenme",
        isSelected: false,
        color: "#FF8947E5",
        img: img3,
        src:"https://images.unsplash.com/photo-1504674900247-0877df9cc836?auto=format&fit=crop&q=60&w=800&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Zm9vZHxlbnwwfDB8MHx8fDI%3D"
    },
    {
        id: 3,
        text: "Obezite Beslenme",
        isSelected: false,
        color: "#66AE4CE5",
        img: img3,
        src:"https://images.unsplash.com/photo-1490645935967-10de6ba17061?auto=format&fit=crop&q=60&w=800&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGZvb2R8ZW58MHwwfDB8fHwy"
    },
    {
        id: 4,
        text: "Bebek Beslenmesi",
        isSelected: false,
        color: "#CCBA1BE5",
        img: img1,
        src:"https://images.unsplash.com/photo-1496412705862-e0088f16f791?auto=format&fit=crop&q=60&w=800&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjF8fGZvb2R8ZW58MHwwfDB8fHwy"
    },
    {
        id: 5,
        text: "Çocuk Beslenmesi",
        isSelected: false,
        color: "#1D9DC6E5",
        img: img2,
        src:"https://images.unsplash.com/photo-1577303935007-0d306ee638cf?auto=format&fit=crop&q=60&w=800&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzN8fGZvb2R8ZW58MHwwfDB8fHwy"
    },
    {
        id: 6,
        text: "Okul Çağı Beslenmesi",
        isSelected: false,
        color: "#E547FFE5",
        img: img2,
        src:"https://images.unsplash.com/photo-1513442542250-854d436a73f2?auto=format&fit=crop&q=60&w=800&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NTV8fGZvb2R8ZW58MHwwfDB8fHwy"
    },
    {
        id: 7,
        text: "Ergen Beslenmesi",
        isSelected: false,
        color: "#9747FFE5",
        img: img3,
        src:"https://images.unsplash.com/photo-1511909525232-61113c912358?auto=format&fit=crop&q=60&w=800&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjV8fGZvb2R8ZW58MHwwfDB8fHwy"
    },
    {
        id: 8,
        text: "Hastalıklarda Beslenme",
        isSelected: false,
        color: "#5C3016E5",
        img: img1,
        src:"https://images.unsplash.com/photo-1506655624258-6e7d8102f90f?auto=format&fit=crop&q=60&w=800&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8ODd8fGZvb2R8ZW58MHwwfDB8fHwy"
    },

]

export const contentRows = [
    {
        id: 1,
        "title-tr": "Kimdir?",
        "title-en": "Who am I?",
        "description-tr": "Ben bir diyetisyenim ve sağlıklı beslenme ve yaşam tarzı konularında uzmanlaştım. Misyonum, sağlığınızı iyileştirmeniz ve daha iyi bir yaşam kalitesi elde etmeniz için size destek sağlamaktır.",
        "description-en": "I am a dietitian, specializing in healthy nutrition and lifestyle matters. My mission is to provide support for you to enhance your health and achieve a better quality of life.",
        image: img3,
        "buttonText-tr": "Görüntüle",
        "buttonText-en": "Preview",
        imagePath: "https://images.unsplash.com/photo-1585358682246-23acb1561f6b?auto=format&fit=crop&q=80&w=2862&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    }
]

export const blogCards = [
    {
        id: 0,
        category: "Sağlık",
        title: "Neden Yiyecekleri İyi ve Kötü Olarak Etiketlemeyi Durdurmalısınız?",
        src: "https://s3-alpha-sig.figma.com/img/2b86/2210/72731f4e0b5d69a95e29801565d5ce29?Expires=1697414400&Signature=DmUImWWOh6-ipTte5M5IX6FeK3V0Gycdxsm8aCqj8k1H47RKR0DfY8ACoPBZ-BM2lFIJGWB2p725aWbUcCNoBPWp52rhOXT3WDX8SuqSqmhe~HCVGaUz10Nir5IxibuUynS0u6-CNQXxtOY0LwcgtiwY4tyWWMLwXv3GYslzs1hDEZtKkgxBbpZBajmNpwD9bX17gW9SKHxWIX9SLOAlD9wgkD5q5FDicrdhKDXS0lrx8BzOGqLFYt05juv2kTrgQjd5dnBkC-bj6bdFIBrGiERSecSd7djq~NoaKCKR5K7eNqll7cIAdiT2S7aRx1Ss4SjCXfMqeMiXZuiRXtOz8Q__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
    },
    {
        id: 1,
        category: "Sağlık",
        title: "Neden Yiyecekleri İyi ve Kötü Olarak Etiketlemeyi Durdurmalısınız?",
        src: "https://s3-alpha-sig.figma.com/img/de7b/0da0/2321f0dc00ee1ffa4497eecb58c91421?Expires=1697414400&Signature=hpgdIWU8d1vHnH13i7E5MgJ7EQMJVZyUAkFg4CcqYO4--ghJr~JZoZf5uHPkRhJPyu2TqDr7kXClXa~3cewUxDf-lgHkL16JSfApITdd2ciK75zV9~kaHKltoqWE~DrzEKt8G-CWfFu-lei~zkXtUhEzjtaVd-K9Jx830ipOrgedAPApyjt0Nonp2UMEqVaHsLzRIv9AhHGj2cNJNyYmGsPm9Hp2S5GdfPii-GnlQrmJ22wOx1f9yEflvFTS9v5mZ4mMzSDd8QphzZI~OlmIXvnqUHrLc08-uqSAiYUoF9WYewxp36LXt2Ctw8Kv9ElXOaGD7OwZkxJv5ZCk3EBnwA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
    },
    {
        id: 2,
        category: "Sağlık",
        title: "Neden Yiyecekleri İyi ve Kötü Olarak Etiketlemeyi Durdurmalısınız?",
        src: "https://s3-alpha-sig.figma.com/img/ab61/855e/88ac6cf3048e8a987d8f5a6432c1c835?Expires=1697414400&Signature=A8P3arAEVg8G4dVaCBhwU-7DEJfsDhwT3dRbSQVL7eK-IdtDjmbxzH1r8yWUV326Vlxi-XPx-dnVhrnC1Q0iGbUBUKN7Bpftity339m~5G7G0VoAXCvnyAeejv19XxktzwXgR7B0rKrKPheebnefSfKMLgmHu8mfxAC29ZELhOzhqJTNEpxC4HhacMQIN542palp1dMUABmg~7y0ekgSfbBT~DcslLlts7cj4Un1u2AFhpnqXypaYOg06ErB6ilmfhD5spLZbseHhQoSJss346TEtmwb5pAFMpgmEZ7S4XOgJA0aO8ztiNpOGUMeuKwM5RtuYCmoL8OcAcb0SQHBQQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
    },
    {
        id: 3,
        category: "Sağlık",
        title: "Neden Yiyecekleri İyi ve Kötü Olarak Etiketlemeyi Durdurmalısınız?",
        src: "https://s3-alpha-sig.figma.com/img/7c36/0841/3c9a1ba5fa770c728580c2024988640f?Expires=1697414400&Signature=mFkRTEir9f4pBzrbpT7~NAGp9i3yVEqJwMHLaP9FgNbPavBQTm-M6Ltn1lTHG-kwXhJ7-fgJrLr7aXIs7gzCYj3PeMMJ3dUNJN1RJIbYDyF-92ERm7llKTF-6v6187pLFkZFFeYkzr48ztIiZgCOqNWIGpW7EaSKv1GYmh71OmXFh7fK0CV1kTEkumW9ApIP1~xYU7gghF594O8dg-41ANZ1RJ4tDAu2iO5DTso~ikiesDkfcD6zvhy8cmAb4spcirFvwgYjDx1G43J~~8lDUTCeLoEOA3YAyD4LekdwY8dXN8t4EPtnpFK~Cz3WvIAfOlE7apC~lZt2L9-zprKgBw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
    },
    {
        id: 0,
        category: "Sağlık",
        title: "Neden Yiyecekleri İyi ve Kötü Olarak Etiketlemeyi Durdurmalısınız?",
        src: "https://s3-alpha-sig.figma.com/img/e847/5476/ce77304b6a72b04937ad575edff156d2?Expires=1697414400&Signature=FSnmcBzWUFmUArGXlUah0ka9YJEiXDztbVoVYm9q5kqyK1iNA2AMjOEz-X0ZhoC5LhMCH9oRZG29cEj3aSRxfUSh5qtihkY4ra4kf-fwEqJcOiOVAX96tKds8DYG82gFM033awTYsEtQb49NrjIPBBpJa7R8EuTbul3VEmG5Crw-M~B0gK-HQ8BOoG4bsm0RTeyzzbkY~3EcEwXC-fJ8p4WIiYAF5DKH4S1dpsQU8U7xTtEjt8uEcGGCtxw2KZ8D0cPGwVHClPNvhCJFdUr~YtkSlZjLgOJRggp5jgXqI-OCuJWyY0JK8jSknQyM8jClUQnby2wYzcs580LH4dP6SA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
    },
    {
        id: 1,
        category: "Sağlık",
        title: "Neden Yiyecekleri İyi ve Kötü Olarak Etiketlemeyi Durdurmalısınız?",
        src: "https://s3-alpha-sig.figma.com/img/c956/aa97/bfe344f369a30b9186c549201ed460bb?Expires=1697414400&Signature=ataIB9U8KjTsgfZ53E5EKez2YnodpqGPCL13LCGFLo491eESlyM6M0BjHP~mpnB5Pf5y2w9fSkA1qxQ9iCeJr13DV2TxXYhKdJwqgbiKMxC5hG27wmXfGJVgV78qU7wvFcsyK9CMdRTtLsvyBxtmIkLJ1l-9H0GtLB7BhXWkJp4DP~yaZPgAUULxqn0-sxrI9VO1tbrY8LX9wS7pHvqbb9xQZiGVwcfIFL5O-D1-iwSQFIqdN1mHeMlwdDVIUJF7o5NpwkawBjXoImqdcO9pKSfL0pcvakK4AK81RvgoIJ7-zN52rI4He~ONl8MMaFlAOg66U3~-ch5xT8lraYECyA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
    },
    {
        id: 2,
        category: "Sağlık",
        title: "Neden Yiyecekleri İyi ve Kötü Olarak Etiketlemeyi Durdurmalısınız?",
        src: "https://s3-alpha-sig.figma.com/img/eeab/54df/e7f053c18587332a673d90fbf7386061?Expires=1697414400&Signature=WlxISQdQwmcMMcOZb6f15VQ1zko~GKKGyX98Dhh8XelZB9cZ3jC-BGxebQVomS1Lur41QS2pxX62RCtKvf9VJaNSXIoUwKp65H33nEI19NfeTZJp~SewtVHGkUIZ1GvSi1QWmdnV2bJbM3ig1yN4z-UOzcZoJc3uEGsdjSdNKJpCYqeLKYVQ7R9A2T0hAOnXIfbMOMN6ezgpX9x2hHUmvVOpO8uvF6DQozmH~QxLEu8uv5Yxc1Hc0EKLUNaCT1UD6yloJ5h9mbdDvFun9nAbOqKGG2zvCuvjZ3B8BgEM4iYaOMoR~n94076ew3HFrPUGlMV1EYTDfAmqVSphE~DFAA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
    },
    {
        id: 3,
        category: "Sağlık",
        title: "Neden Yiyecekleri İyi ve Kötü Olarak Etiketlemeyi Durdurmalısınız?",
        src: "https://s3-alpha-sig.figma.com/img/60e0/c63a/efe11206fc654e23c5e03152f04e5316?Expires=1697414400&Signature=YbhIsUah-wFYCdVm7tBQmZtA5-OM4Yw6m9bXslXM0rIqDsl5rTQtN07Pp3AxBTEEbH6gEFAfUg8-RIcHJy1wOihvbIU1fgVWN5DuAa~wSnXKp1ERkKH5zQbuKStDA8JKUf3IvD-lAL6NVB99LTeJlGpqdcu5WVqSUAa~AYYXc9Ke~ESzUoelAa6rWE3fDX9iCqloNQwqvL5214t0TA7Hm3DcCepxRkfP13w1LHP95dm8ZZfqtKMJoCDFkd4pDt9peMwd~gsMHa6RxIlBQLnKbw4UvmA1rAPF8V~xpG8UXpvS5T1b6-JnJmJe1HsQy0r-oabIoRWXXiSRQ-ePvz8TFQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
    },
    {
        id: 0,
        category: "Sağlık",
        title: "Neden Yiyecekleri İyi ve Kötü Olarak Etiketlemeyi Durdurmalısınız?",
        src: "https://s3-alpha-sig.figma.com/img/776d/01f8/86101c736815b35017f45fa9a36dc8f3?Expires=1697414400&Signature=AvcYP3JEREqdGq5A5CYSHlKRSegDZq1tYyThSzhaw~fd2fN3nCVfFNjh2P25cQhk4zt16dKaRhSofUlRRPvWmlkw-lo007eKaAWCewWJldg-w8GYufG-TOyEEmrA4ycw1~v9Zr1oZ7t1Iik3pVjfVNbCYjVfH99ZeTNIjGnX4m7haAxm23F0JZzTxl0ycM9xr-YRy2nPoBuetud-4FVOD1xlT~F5wxyqBKIVw9vx-sy0BGUyHnOYuHZYcZSnvQG7Y2se4fXowGIwT-Gj9OdrROVR-dhFbkENTtpaTUD~yXEklhX9XIs5o4RAcKZPCT1eWwSMglS7r557SqwvagCkaA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
    },
    {
        id: 1,
        category: "Sağlık",
        title: "Neden Yiyecekleri İyi ve Kötü Olarak Etiketlemeyi Durdurmalısınız?",
        src: "https://s3-alpha-sig.figma.com/img/a1f5/d70d/3c1785a30cf43b40060383b33386323c?Expires=1697414400&Signature=YXt9GnlyUbYJpYJEUZj-4dIwrf0FKURLEmjFJ1Rrf3cnxx3bezfIPipg-QAGi~xitsEW0ivEW2UJAaBSk57WIIWh~S-ilq~D1-kuorfHOuP7hicq0j9DgNq8N9VlKkQhn0E8JFK2x4dHqdlAsQ1InAo1KeSy5TGVfin3dOHmP0axTBD1DOgmrHw21kLqDpCYfp2HrgfTTb57AqualEYPINexzzeNsOOmagr8ApDZ-vXRIjypaI9UNWveW~vEUDH~6da1c6ZJ-67rY2Beqw1l0RF2EBdHD-uj29FomI5Kfn9lvroEmFbi~Twy9AYgHYeeDCRioRvhimD75dM3SywwUA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
    },
]

export const about = {
    "tr": `
        Merhaba! Ben Yekta, yemekleri gerçekten seven bir diyetisyen ve beslenme terapistiyim. 
        Sertifikalı sezgisel yeme danışmanı olarak, diğerlerine diyet kurallarını terk etmeleri 
        ve yemeğin keyfini yeniden keşfetmelerine yardımcı olmaktan tutkuyla bahsediyorum. Amacım,
        müşterilerime ve okuyucularıma yemekle ilgili ilişkilerini daha sağlıklı hale getirmelerine 
        ve kısıtlama yerine beslenmeye dayalı yeme alışkanlıklarıyla sağlıklarını iyileştirmelerine 
        yardımcı olmaktır. Çoğu diyetisyen gibi, beslenmenin sadece "daha az yemek" ve "doğru yemek" 
        olduğunu düşünürdüm. Ağırlık ve sağlık arasındaki ilişki hakkında öğretilenlere inanıyordum. 
        Bu inançlar, yemek ve yemeği stresli ve karmaşık hale getirdi. Müşterilerle çalışmaya başladığımda
        , yaklaşımımın yardımcı olmadığını hızla fark ettim. Yemek ve yemeğe odaklanmanın ağırlık ve katı 
        diyet kuralları haline geldiğini gördüm; bu, basit, besleyici bir öz bakım eylemi olması gereken bi
        r şeyi, anksiyete dolu bir göreve dönüştürüyordu.<br/><br/>
        Neyse ki, hızla fark ettim ki yemek, yakıttan çok daha fazlasıdır ve diyet kuralları ve katılık 
        cevap değildir. Sağlığı takip etmenin amacının daha iyi bir yaşam kalitesi sürmek olduğunu fark
        ettim ve eğer yemek ve yemek stresli hissettiriyorsa, bu aslında bu hedefe yardımcı olmuyor 
        demektir. Teraziye odaklanmanın, sağlığı teşvik eden davranışlarla etkileşim yeteneğini nasıl 
        engellediğini gördüm. Ayrıca, kilo ile olan kültürel takıntımızın yeme bozukluklarını ve 
        düzensiz yeme alışkanlıklarının bir salgınını nasıl tetiklediğini öğrendim. Kendi rolümü yemeğe 
        sağlıklı bir ilişki geliştirmelerine yardımcı olmak ve yemek seçimlerini beslenme, zevk ve içsel 
        bilgelikten bir yerden yapmayı öğrenmek olarak görmeye başladım. Bu farkındalıklarla sezgisel yeme,
        bilinçli yeme ve Her Bedene Uygun Sağlık (Health at Every Size®) hakkında öğrenebileceğim her şeyi 
        öğrenmeye ve bu diyet dışı yaklaşımları uygulamama ve kişisel yaşantıma entegre etmeye başladım.
    `,
    "en": `
        Hello! I'm Yekta, a dietitian and nutrition therapist who truly enjoys food. As a certified intuitive 
        eating counselor, I'm passionate about helping others ditch diet rules and rediscover the joy of eating. 
        My goal is to assist my clients and readers in making their relationship with food healthier and improving 
        their well-being through nourishment-based habits, rather than restriction.
        <br/><br/>
        Like many dietitians, I used to think that nutrition was all about "eating less" and "eating right." I believed 
        what was taught about the relationship between weight and health. These beliefs turned food and eating into a 
        stressful and complicated task. However, as I began working with clients, I quickly realized that my approach 
        wasn't helpful. I saw how focusing on food and eating had turned a simple act of self-care into an anxiety-filled
         mission, full of weight and rigid dietary rules.
        <br/><br/>
        Fortunately, I soon realized that food is much more than just fuel and that diet rules and rigidity are not
         the answer. I recognized that the purpose of pursuing health is to enjoy a better quality of life, and if 
         food and eating make you feel stressed, it's not actually contributing to that goal. I saw how the emphasis 
         on the scale hindered the ability to interact with health-promoting behaviors. I also learned how our cultural 
         obsession with weight triggered eating disorders and irregular eating habits. I began to see my role as 
         helping individuals develop a healthy relationship with food and learn to make food choices from a place 
         of nourishment, pleasure, and inner wisdom. With these insights, I started to learn everything I could 
         about intuitive eating, mindful eating, and Health at Every Size® and began integrating these non-diet 
         approaches into my personal life and practice.
    `
}