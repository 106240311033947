import React from 'react'
import Belt from '../components/belt/Belt'
import ContentRow from '../components/content/ContentRow'
import Row from '../components/base/row/Row'
import { useTranslation } from 'react-i18next'
import { useBio } from '../hooks/useBio'

const About = () => {
    const { i18n } = useTranslation();
    const bio = useBio();
    const lang = i18n.language;

    return (
        <>
            <Belt />
            {
                bio ?
                    <>
                        <ContentRow className="aboutContent" buttonDisabled item={bio} imageFirst />
                        <Row className="aboutRow" mb={48} mt={72} split>
                            <div className='aboutText' dangerouslySetInnerHTML={{ __html: bio["bio-" + lang] }}>

                            </div>
                            <img src={bio?.bioImg} className='aboutImg' alt='' />
                        </Row>
                    </> : null
            }

        </>
    )
}

export default About