import React, { memo } from 'react'
import { useTranslation } from 'react-i18next';
import { isEmpty } from '../../../helper/isEmpty';
import { useNavigate } from 'react-router-dom';

const Squares = (props) => {
    const { item } = props;
    const { i18n, t } = useTranslation();
    const lang = i18n.language;
    const navigate = useNavigate();

    const cardClick = () => {
        navigate('/blog', { state: { item: item } });
    }


    return (
        <div
            onClick={() => cardClick()}
            className='squares'>
            <div
                style={{
                    backgroundColor: item.color,
                    opacity: props.isSelected ? 0.1 : 0.8
                }}
                className='squareDummy' />
            <img
                src={item.src}
                className={`squareImg ${props.isSelected && 'zoom'}`}
                alt='' />
            <label className='squareText'>
                {isEmpty(item["name-tr"]) ? t(item.text) : item["name-" + lang]}
            </label>
        </div>
    )
}

export default memo(Squares);