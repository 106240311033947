import React, { memo, useRef } from 'react'
import './Footer.css';
import Row from '../row/Row';
import { useTranslation } from 'react-i18next';
import logo from '../../../assets/images/footerLogo.svg';
import message from '../../../assets/images/message.svg';
import instagram from '../../../assets/images/instagram.svg';
import youtube from '../../../assets/images/youtube.svg';
import facebook from '../../../assets/images/facebook.svg';
import linkedin from '../../../assets/images/linkedin.svg';
import * as EmailValidator from 'email-validator';
import { AddMail } from '../../../store/firebase/firebase';
import { toast } from 'react-toastify';
const Footer = (props) => {
    const { t } = useTranslation();
    const inputRef = useRef();
    const footerButtonClick = () => {
        const mail = inputRef.current.value;
        const result = EmailValidator.validate(mail);
        if (result) {
            AddMail({ eMail: mail })
                .then((res) => {
                    if (res === 1) {
                        inputRef.current.value = "";
                        toast(t('mailSuccess'));
                    }
                    else if(res === 0){
                        toast(t('mailExist'));
                    }
                    else{
                        toast(t('mailFailed'));
                    }
                })
        }
    }
    return (
        <footer>
            <Row center vertical>
                <span className='footerTitle'>
                    {t('firstUser')}
                </span>
                <label className='footerSubtext'>
                    {t('news')}
                </label>
                <div className='footerInputFrame'>
                    <input
                        ref={inputRef}
                        placeholder={t('mailAddress')}
                        className='footerInput' />
                    <button
                        onClick={() => footerButtonClick()}
                        className='footerButton'>
                        {t('aboneol')}
                        <img src={message} alt='' />
                    </button>
                </div>
                <img
                    src={logo}
                    className='footerLogo' alt='' />
                <div className='linksFrame'>
                    <a href='https://www.instagram.com/onkolojidiyetisyeniyekta/'>
                        <img className='links' src={instagram} alt='' />
                    </a>
                    <a href='https://www.youtube.com/channel/UCFwcHMwfO8OvXHm-knZ5Z1w'>
                        <img className='links' src={youtube} alt='' />
                    </a>
                    <a href='https://www.facebook.com/profile.php?id=100078752541576'>
                        <img className='links' src={facebook} alt='' />
                    </a>
                    <a href='https://tr.linkedin.com/in/uzman-dyt-yekta-%C3%A7apal%C4%B1-6473501a3'>
                        <img className='links' src={linkedin} alt='' />
                    </a>
                </div>
                <label className='copyright'>
                    Copyright © 2023
                </label>
            </Row>
        </footer>
    )
}

export default memo(Footer)