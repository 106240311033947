import React, { memo } from 'react'
import BlogCard from './BlogCard'
import { useTranslation } from 'react-i18next'
import BlogButton from './BlogButton'
import Row from '../base/row/Row'
import { useNavigate } from 'react-router-dom'
import { useBlogs } from '../../hooks/useBlogs'

const BlogCardsRow = ({ categories, className, customTitle, customClick }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const blogs = useBlogs();
    const goCard = (item) => {
        if (customTitle) {
            customClick(item)
        }
        navigate(`/blogDetail/${item.id}`, { state: { blog: item } })
    }

    return (
        <Row vertical className={className}>
            <span className='lastWritten'>
                {customTitle ? customTitle : t('Son yazılar')}
            </span>
            <div className='blogCardsRow'>
                {
                    blogs?.slice(0, 4).map((item) => {
                        return (
                            <BlogCard
                                click={() => goCard(item)}
                                categories={categories}
                                key={item.id}
                                item={item} />
                        )
                    })
                }
            </div>
            <BlogButton
                click={() => navigate('/blog')}
                text={t("Tüm Blog yazılarını gör")} />
        </Row>

    )
}

export default memo(BlogCardsRow)