import DefaultErrorPage from '../errors/DefaultErrorPage';
import Default from '../layouts/Default';
import About from '../pages/About';
import Blog from '../pages/Blog';
import BlogDetail from '../pages/BlogDetail';
import Contact from '../pages/Contact';
import Home from '../pages/Home';
export const routes = [
    {
        path: '/',
        component: Home,
        title: "Yekta Çapalı | Anasayfa",
        permit: ["anyAllow"],
        layout: Default,
        errorPage: DefaultErrorPage
    },
    {
        path: '/about',
        component: About,
        title: "Yekta Çapalı",
        title: "Yekta Çapalı | Hakkında",
        permit: ["anyAllow"],
        layout: Default,
        errorPage: DefaultErrorPage
    },
    {
        path: '/blog',
        component: Blog,
        title: "Yekta Çapalı | Bloglar",
        permit: ["anyAllow"],
        layout: Default,
        errorPage: DefaultErrorPage
    },
    {
        path: '/contact',
        component: Contact,
        title: "Yekta Çapalı | İletişim",
        permit: ["anyAllow"],
        layout: Default,
        errorPage: DefaultErrorPage
    },

    {
        path: '/blogDetail/:id',
        component: BlogDetail,
        title: "Yekta Çapalı | Blog",
        permit: ["anyAllow"],
        layout: Default,
        errorPage: DefaultErrorPage
    },
]