import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

const NavItems = (props) => {
    const { t } = useTranslation();
    const { item } = props
    return (
        <label
            onClick={() => props.click()}
            className='navItems'
            style={{
                color: props.isSelected ? '#10B981' : 'black',
                borderColor: props.isSelected ? '#10B981' : 'transparent'
            }}
        >
            {t(item.text)}
        </label>
    )
}

export default memo(NavItems)