import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getBio } from "../store/firebase/firebase";
import { Slice } from "../store/_redux/base/slice";

export const useBio = () => {
    const bio = useSelector((state) => state.base.bio);
    const dispacth = useDispatch();
    useEffect(() => {
        getBio()
            .then((res) => {
                if (res) {
                    dispacth(Slice.actions.setBio(res[0]))
                }
            })
    }, [dispacth])
    return bio;
}