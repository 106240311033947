import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getCategories } from "../store/firebase/firebase";
import { Slice } from "../store/_redux/base/slice";
import { squareData } from "../data/staticDatas";
const squareParser = (arr) => {
    let newArr = [];
    for (let i = 0; i < arr.length; i++) {
        newArr.push({
            ...squareData[i],
            ...arr[i]
        })
    }
    return newArr;
}
export const useCategories = () => {
    const categories = useSelector((state) => state.base.categories);
    const dispacth = useDispatch();
    console.log("kfjhsdjkfhsdjkh");
    useEffect(() => {
        getCategories()
            .then((res) => {
                if (res) {
                    dispacth(Slice.actions.setCategories(squareParser(res)))
                }
            })
    }, [dispacth])
    return categories
}