import React, { memo } from 'react'
import arrow from '../../../assets/images/arrowRight.svg';
const BaseButton = ({ text, onClick, mt, style, icon, withArrow }) => {
    return (
        <button
            onClick={() => onClick()}
            style={{
                ...style,
                marginTop: mt
            }}
            className='baseButton'>
            {text}
            {
                withArrow ?
                    <img src={arrow} alt='' /> : { icon }
            }
        </button>
    )
}

export default memo(BaseButton)