export const checkIncludes = (data, text) => {
    const keys = Object.keys(data);
    let flag = false;
    for (let i = 0; i < keys.length; i++) {
        if (data[keys[i]].toLowerCase().includes(text.toLowerCase())) {
            flag = true;
            break;
        }
    }
    return flag
}