import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

const Belt = () => {
    const { t } = useTranslation();
    return (
        <div className='belt'>
            <article />
            <label className='beltTitle'>
                {t('heroTitle')}
            </label>
        </div>
    )
}

export default memo(Belt)