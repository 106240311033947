import React, { memo } from 'react'
import Row from '../base/row/Row';
import '../base/hero/hero.css';
import BaseButton from '../base/buttons/BaseButton';
import { useTranslation } from 'react-i18next';
import { contentRows } from '../../data/staticDatas';
const ContentRow = (props) => {
    const { item } = props;
    const { i18n } = useTranslation();
    const lang = i18n.language;
    return (
        <Row split className={"contentRow " + props.className}>
            <div className='contentRowLeft' style={{ order: props.imageFirst ? 1 : 0 }}>
                <h2 className='heroTitle'>
                    {item["cardTitle-" + lang]}
                </h2>
                <label className='heroText'>
                    {item["cardText-" + lang]}
                </label>
                {
                    !props.buttonDisabled ?
                        <BaseButton
                            onClick={() => props.click()}
                            mt={24}
                            withArrow
                            text={contentRows[0]['buttonText-' + lang]} /> : null
                }

            </div>
            <img src={item.cardImg} className='contentRowImage' alt='' />
        </Row>
    )
}

export default memo(ContentRow)