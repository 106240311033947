import { createSlice } from "@reduxjs/toolkit";
import { squareData } from "../../../data/staticDatas";

const initialState = {
    base: "base",
    squares: null,
    me: null,
    blogs: null,
    categories: squareData,
    bio: null,
};
export const callTypes = {
    list: "list",
    action: "action"
};
//State işlemleri için
export const Slice = createSlice({
    name: "base",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
                state.actionsLoading = false;
            } else {
                state.actionsLoading = false;
                state.listLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
                state.actionsLoading2 = true;
            }
        },
        setSquares: (state, action) => {
            state.error = null;
            state.squares = action.payload;
        },
        setMe: (state, action) => {
            state.me = action.payload
        },
        setBlogs: (state, action) => {
            state.blogs = action.payload
        },
        setCategories: (state, action) => {
            state.categories = action.payload
        },
        setBio: (state, action) => {
            state.bio = action.payload
        },
    }
});



