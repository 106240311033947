import React, { memo, useState } from 'react'
import './Nav.css';
import Row from '../row/Row';
import { navItems } from '../../../data/staticDatas';
import NavItems from './NavItems';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import lang from '../../../assets/images/greenLang.svg';
import arrowDown from '../../../assets/images/arrowDown.svg';
const Nav = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const [show, setShow] = useState(false);
    const navClick = (item) => {
        navigate(item.path);
    }
    const showMenu = () => {
        setShow((current) => {
            return !current
        })
    }
    const hideMenu = () => {
        if (show) {
            setTimeout(() => {
                setShow(() => {
                    return false
                })
            }, 200);
        }
    }
    const changeLang = (lang) => {
        i18n.changeLanguage(lang);
        setShow(() => {
            return false
        })
    }
    return (
        <nav>
            <Row
                center
                vertical>
                <label className='navLogo'>
                    Onkoloji Diyetisyeni Yekta Çapalı
                </label>
                {/* <img className='navLogo' src={logo} alt='' /> */}
                <div className='navFrame'>
                    {
                        navItems.map((item) => {
                            return (
                                <NavItems
                                    isSelected={location.pathname === item.path}
                                    key={item.id}
                                    item={item}
                                    click={() => navClick(item)}
                                />
                            )
                        })
                    }
                    <div className='langFrame'>
                        <button className='langButton' onClick={() => showMenu()} onBlur={() => hideMenu()}>
                            <img src={lang} alt='' />
                            {i18n.language.toUpperCase()}
                            <img src={arrowDown} alt='' />
                        </button>
                        <div className='langMenu' style={{ display: show ? 'flex' : 'none' }}>
                            <button
                                onClick={() => changeLang('tr')}
                                className={`langButtons ${i18n.language.toUpperCase() === 'TR' ? 'selectedLang' : ''}`}>
                                Türkçe
                            </button>
                            <button
                                onClick={() => changeLang('en')}
                                className={`langButtons ${i18n.language.toUpperCase() !== 'TR' ? 'selectedLang' : ''}`}>
                                English
                            </button>
                        </div>
                    </div>
                </div>
            </Row>
        </nav>
    )
}

export default memo(Nav)